import * as IntroJS from "intro.js";

export function startIntro(onDone: () => void) {
  setTimeout(() => {
    IntroJS()
      .setOptions({
        steps: [
          {
            title: "Welcome 👋",
            intro:
              "Colorbattle is a turn-based color-picking game. The goal is to be the first player to take over half of the field.",
          },
          {
            title: "The field 🌼",
            intro:
              "The field is made up of different colors. It also shows your color (purple, top left) and the opponent's color (black, bottom right). All the fields you take over will be painted in your color.",
            element: '[data-intro-id="field"]',
          },
          {
            title: "Choosing a color 🎨",
            intro:
              "When it is your turn, select one of these colors. By doing so, you will take over all the fields of that color that touch one of your fields. Diagonal fields are not touching your fields.",
            element: '[data-intro-id="interaction"]',
          },
          {
            title: "Status",
            intro:
              "This text informs you about who's turn currently is and which color they control. In a multiplayer game, you can be either purple or black.",
            element: '[data-intro-id="status"]',
          },
          {
            title: "Progress",
            intro:
              "Your progress is shown on the left, your opponent's progress is on the right.",
            element: '[data-intro-id="progress"]',
          },
          {
            title: "Modes 🎲",
            intro:
              "You are currently playing against the AI 🤖. You can also challenge random players or invite a friend. To do so, select `Multiplayer` on the home screen. 👫.",
          },
          {
            title: "Single Player 🎲",
            intro:
              "You can also play a relaxed round without an opponent. Select `Single Player` on the home screen and you play a round by yourself. The goal is to fill the entire field 🌾.",
          },
          {
            title: "Daily Challenge 🗓️",
            intro:
              "A special mode is the `Daily Challenge`. It's an AI game that has a different field every day. Try to beat the AI in as least moves as possible.",
          },
          {
            title: "Have fun 🥳",
            intro: "Enjoy the game and good luck 🤩",
          },
        ],
      })
      .oncomplete(onDone)
      .onexit(onDone)
      .start();
  }, 200);
}

export function stopIntro() {
  IntroJS().exit();
}
