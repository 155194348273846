import * as React from "react";

import "./Interaction.css";
import "./colors.css";

type Props = {
  width: number;
  onColorSelected: (color: number) => void;
};

export const Interaction = React.memo<Props>(({ width, onColorSelected }) => {
  const onClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const color = (event.target as HTMLDivElement).dataset["color"] || "0";
      onColorSelected(parseInt(color));
    },
    []
  );

  const colorButtonStyle = React.useMemo(
    () => ({
      width: width / 5,
      height: width / 5,
    }),
    [width]
  );

  return (
    <div className="Interaction" onClick={onClick} data-intro-id="interaction">
      <div
        className="Interaction__color color__0"
        data-color={0}
        style={colorButtonStyle}
      />
      <div
        className="Interaction__color color__1"
        data-color={1}
        style={colorButtonStyle}
      />
      <div
        className="Interaction__color color__2"
        data-color={2}
        style={colorButtonStyle}
      />
      <div
        className="Interaction__color color__3"
        data-color={3}
        style={colorButtonStyle}
      />
      <div
        className="Interaction__color color__4"
        data-color={4}
        style={colorButtonStyle}
      />
    </div>
  );
});

Interaction.displayName = "Interaction";
