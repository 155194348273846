import { createMachine, assign, ActionTypes } from "xstate";
import {
  selectColor,
  createGame,
  aiSelectColor,
  aiGameMove,
} from "../shared/logic";
import { Game, PlayerIndicator } from "../shared/types";

type AiMatchEvent =
  | { type: "COLOR_SELECTED"; color: number }
  | { type: "REMATCH_REQUESTED" }
  | { type: "LEAVE" };

export type AiGameType = "daily" | "regular";

type Context = {
  moves: number;
  game: Game;
  gameType: AiGameType;
  onAttempt: () => void;
  onWin: () => void;
  onLoss: () => void;
};

export const localPlayer: PlayerIndicator = "a";
export const aiPlayer: PlayerIndicator = "b";

export const AiMatchMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAOhkICd0AXAqAYgA9ZqawT0AzasC5ARgAMgovTRY8hUuV406iUAAcA9rFy1l+BSEaIAzAHYAHCQCsBgCx7TANhsBOfjauCbAGhABPfQZL9+BvZGRgBMRoG29oKmAL4xHuI4BMQkigA26J509ADCAPIAMnkASgD6AMoAogWVOQAqlQAi2ipqGlpIOohG9iEkgsaCIa7+BpYGHt4IzjYk9vNB9mMWIfwWNnEJGElSqRlZ+Awtquq4mtq6CEauJEYWjnoWQo-8wZOI5iQ2AUGBIUu9AyCCybECJSQpdC4ACyygAbmB6Mc2mcOqBLk5+GZ7IY1jZrKFzO8ECFhiQ9BS9GFjEZTHolrF4mDthDSFDYQimCw2BxuLxkABBACSpUa1QFAE1RODkmyYfCwMjTudOhjvtjcesCSEiV4PoITPd5qFLPTwvxQTLdgqKPRipVoQK6jkABKle0ARQAqpVyg1mp1Wsq0V0rgZTF9TPxTPc9NGnEtidZ7CQdQZhsILHT+CEQUyrSkIGBMLgiwB1PA4AAK+14QtgADkwIxqEjAyd2hd9MESOnrPxev5jbYkzYI8EjDnHkNLBYDHEmfhlEX4J0C9IwJQ5IclZ3VYhXrMVuZ6TYQhT7OYJnqEKZBH4fkYzRZhJPXJaWbK9pl5O2USr0Q+exZmGAw9B6UwjDPXNTGJWw5n4ONemBUlhDjD8JC-dkFV3VEu2mNZ+hPfwozHaN3BvBw9DMGwDX8EIbCgkJ53zT9rQRChcIA0MhBjfoIlMUxc0sQR5mJAdfCCJ5wgMRixgMC1WMw3YixLctK2wGtMjrRtm2oLiQ0uZwTFMXo9EzSCVmjYlVj6J9KWAgYGKEIwMJ2YgDPwxDfFMzV8UgtNiQAWkeW5QkEAdwvDKcFxiIA */
  createMachine<Context, AiMatchEvent>(
    {
      id: "(machine)",
      initial: "generating",
      on: {
        LEAVE: { target: "over", actions: ["onLoss"] },
      },
      states: {
        generating: {
          entry: ["onAttempt", "resetMoves"],
          after: {
            "100": {
              actions: "generateGame",
              target: "playing",
            },
          },
        },
        playing: {
          always: [
            {
              cond: "didPlayerWin",
              target: "over",
              actions: "onWin",
            },
            {
              cond: "didAiWin",
              target: "over",
              actions: "onLoss",
            },
          ],
          on: {
            COLOR_SELECTED: {
              actions: ["setColor", "increaseMoves"],
              cond: "isCurrentPlayerMove",
              target: "aiMove",
            },
          },
        },
        aiMove: {
          always: [
            {
              cond: "didPlayerWin",
              target: "over",
              actions: "onWin",
            },
            {
              cond: "didAiWin",
              target: "over",
              actions: "onLoss",
            },
          ],
          after: {
            AI_DELAY: {
              actions: "aiMove",
              target: "playing",
            },
          },
        },
        over: {
          on: {
            REMATCH_REQUESTED: {
              actions: ["onAttempt", "generateRematch", "resetMoves"],
              target: "decideWhichPlayerIsNext",
            },
          },
        },
        decideWhichPlayerIsNext: {
          always: [
            {
              cond: "isCurrentPlayerMove",
              target: "playing",
            },
            {
              cond: "isAiMove",
              target: "aiMove",
            },
          ],
        },
      },
    },
    {
      actions: {
        increaseMoves: assign({
          moves: (ctx) => ctx.moves + 1,
        }),
        resetMoves: assign({
          moves: (_) => 0,
        }),
        generateGame: assign({
          game: (ctx) => {
            return generateGame({
              initialPlayer: localPlayer,
              gameType: ctx.gameType,
            });
          },
        }),
        generateRematch: assign({
          game: (ctx) => {
            if (ctx.game.winner === localPlayer) {
              return generateGame({
                initialPlayer: aiPlayer,
                gameType: ctx.gameType,
              });
            }
            return generateGame({
              initialPlayer: localPlayer,
              gameType: ctx.gameType,
            });
          },
        }),
        setColor: assign({
          game: (ctx, event) => {
            if (!(event.type === "COLOR_SELECTED")) {
              return ctx.game;
            }
            const newGame = aiGameMove({
              game: ctx.game,
              player: localPlayer,
              selectFn: ({ field, player }) =>
                selectColor(field, event.color, player),
            });
            newGame.currentPlayer = aiPlayer;
            return { ...newGame };
          },
        }),
        aiMove: assign({
          game: (ctx) => {
            const newGame = aiGameMove({
              game: ctx.game,
              player: aiPlayer,
              selectFn: aiSelectColor,
            });
            newGame.currentPlayer = localPlayer;
            return { ...newGame };
          },
        }),
        onAttempt: (ctx) => ctx.onAttempt(),
        onWin: (ctx) => ctx.onWin(),
        onLoss: (ctx) => ctx.onLoss(),
      },
      guards: {
        didPlayerWin: (ctx) => ctx.game.winner === localPlayer,
        didAiWin: (ctx) => ctx.game.winner === aiPlayer,
        isCurrentPlayerMove: (ctx) => {
          return ctx.game.currentPlayer === localPlayer;
        },
        isAiMove: (ctx) => {
          return ctx.game.currentPlayer === aiPlayer;
        },
      },
      delays: {
        AI_DELAY: () => 1000 + Math.random() * 1500,
      },
    }
  );

function generateGame({
  initialPlayer,
  gameType,
}: {
  initialPlayer: PlayerIndicator;
  gameType: AiGameType;
}): Game {
  const game = createGame({
    playerId: initialPlayer,
    type: gameType === "daily" ? "daily-ai" : "ai",
  });
  const lastIndex = game.field.posessions.length - 1;
  game.field.posessions[0][0] = localPlayer;
  game.field.posessions[lastIndex][lastIndex] = aiPlayer;
  game.state = "playing";
  game.players = {
    a: localPlayer,
    b: aiPlayer,
  };
  return game;
}
