import * as React from "react";
import { Game as GameType } from "../../shared/types";
import { Game } from "./Game";

import "./Rematch.css";

type Props = {
  game: GameType;
  playerId: string;
  moves: number;
  onPlayAgain: () => void;
};

export const PlayAgain = React.memo<Props>(
  ({ game, playerId, moves, onPlayAgain }) => {
    return (
      <div className="Rematch">
        <div className="Rematch__message">
          {`You finished in ${moves} moves. Wanna play again?`}
          <button onClick={onPlayAgain} className="Rematch__cta">
            Yes
          </button>
        </div>

        <Game game={game} playerId={playerId} onColorSelected={() => {}} />
      </div>
    );
  }
);
