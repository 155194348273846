import * as React from "react";
import { Game, PlayerIndicator } from "../../shared/types";
import { Point } from "./Point";

import "./Message.css";

type Props = {
  game: Game;
  playerId: string;
  moves?: number;
};

export const Message = React.memo<Props>(({ game, playerId, moves }) => {
  const itsThePlayersTurn = game.currentPlayer === playerId;
  const currentPlayerIndicator =
    game.players.a === game.currentPlayer ? "a" : "b";
  const isOver = !!game.winner;
  const didPlayerWin = game.winner === playerId;
  let animationType = "default";
  const messageStr = itsThePlayersTurn
    ? "It's your turn"
    : "It's your opponent's turn";
  let message = (
    <>
      <Point
        width={14}
        height={14}
        posession={currentPlayerIndicator}
        color={0}
      />
      <div className="mx-2">{messageStr}</div>
      <Point
        width={14}
        height={14}
        posession={currentPlayerIndicator}
        color={0}
      />
    </>
  );
  if (isOver) {
    if (didPlayerWin) {
      message = <>You won 🎉 {moves ? `(${moves} moves)` : ""}</>;
      animationType = "won";
    } else {
      message = <>You lost 😢 {moves ? `(${moves} moves)` : ""}</>;
      animationType = "lost";
    }
  }

  return (
    <div
      key={Math.random().toString()}
      className={`Message m-${animationType}`}
    >
      {message}
    </div>
  );
});
