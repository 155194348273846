import "spectre.css/dist/spectre.min.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Route } from "wouter";
import { Provider } from "jotai";

import * as Urls from "../shared/urls";

import { Home } from "./routes/Home";
import { Multi } from "./routes/Multi";
import { Stats } from "./routes/Stats";
import { About } from "./routes/About";

import { SinglePlayerGame } from "./SinglePlayerGame";
import { AiMatchGame } from "./AiMatchGame";
import { MultiplayerGame } from "./MultiplayerGame";

import "@fontsource/mochiy-pop-one";
import "./index.css";

function App() {
  return (
    <Provider>
      <Route path={Urls.home}>
        <Home />
      </Route>
      <Route path={Urls.multiplayer}>
        <Multi />
      </Route>
      <Route path={Urls.gameSingle}>
        <SinglePlayerGame />
      </Route>
      <Route path={Urls.gameChallenge}>
        <AiMatchGame showIntro={false} gameType="daily" />
      </Route>
      <Route path={Urls.gameAi}>
        <AiMatchGame showIntro={false} gameType="regular" />
      </Route>
      <Route path={Urls.learnToPlay}>
        <AiMatchGame showIntro={true} gameType="regular" />
      </Route>
      <Route path={Urls.game}>
        <MultiplayerGame />
      </Route>
      <Route path={Urls.gameInvite}>
        <MultiplayerGame type="invite" />
      </Route>
      <Route path={Urls.gameWithIdWildcard}>
        {(params) => <MultiplayerGame gameId={params.id} />}
      </Route>
      <Route path={Urls.stats}>
        <Stats />
      </Route>
      <Route path={Urls.about}>
        <About />
      </Route>
    </Provider>
  );
}

const app = document.getElementById("app");
ReactDOM.render(<App />, app);
