import { useMachine } from "@xstate/react";
import * as React from "react";
import { useUpdateAtom } from "jotai/utils";
import { AiMatchMachine, AiGameType } from "../machines/ai-match-machine";
import { Game } from "./components/Game";
import { Rematch } from "./components/Rematch";
import { startIntro, stopIntro } from "./intro";
import { statsAtom } from "./persistence";
import type { Stats } from "./persistence";

import "intro.js/minified/introjs.min.css";
import { RouteContent } from "./components/RouteContent";

export const AiMatchGame: React.FC<{
  showIntro: boolean;
  gameType: AiGameType;
}> = ({ showIntro = true, gameType }) => {
  const setStats = useUpdateAtom(statsAtom);
  const [state, send] = useMachine(AiMatchMachine, {
    devTools: true,
    context: {
      moves: 0,
      gameType,
      onAttempt: () => {
        setStats((oldStats) => {
          const { stateChunk, stateKey } = getCorrectStatsChunk(
            gameType,
            oldStats
          );
          return {
            ...oldStats,
            [stateKey]: {
              ...stateChunk,
              attempts: stateChunk.attempts + 1,
            },
          };
        });
      },
      onLoss: () => {
        setStats((oldStats) => {
          const { stateChunk, stateKey } = getCorrectStatsChunk(
            gameType,
            oldStats
          );

          return {
            ...oldStats,
            [stateKey]: {
              ...stateChunk,
              losses: stateChunk.losses + 1,
              streak: 0,
            },
          };
        });
      },
      onWin: () => {
        setStats((oldStats) => {
          const { stateChunk, stateKey } = getCorrectStatsChunk(
            gameType,
            oldStats
          );
          return {
            ...oldStats,
            [stateKey]: {
              ...stateChunk,
              wins: stateChunk.wins + 1,
              streak: stateChunk.streak + 1,
            },
          };
        });
      },
    },
  });
  const [introDone, setIntroDone] = React.useState(showIntro ? false : true);

  const onColorSelected = React.useCallback((color: number) => {
    send({ type: "COLOR_SELECTED", color });
  }, []);

  // Stop the intro when we're navigating away
  React.useEffect(() => {
    return stopIntro;
  }, []);

  const onLeave = React.useCallback(() => {
    if (state.value !== "over") {
      send("LEAVE");
    }
  }, [state]);

  const { game } = state.context;

  switch (state.value) {
    case "playing":
    case "aiMove":
      if (showIntro && !introDone) {
        startIntro(() => setIntroDone(true));
      }
      return (
        <RouteContent backTo="/" hasWrapper={false} onBack={onLeave}>
          <Game
            game={state.context.game}
            playerId="a"
            onColorSelected={onColorSelected}
            moves={state.context.moves}
          />
        </RouteContent>
      );
    case "over":
      return (
        <RouteContent backTo="/" hasWrapper={false}>
          <Rematch
            game={game}
            playerId="a"
            onRematchRequested={() => {
              send({ type: "REMATCH_REQUESTED" });
            }}
            moves={state.context.moves}
          />
        </RouteContent>
      );
  }

  return null;
};

function getCorrectStatsChunk(gameType: AiGameType, stats: Stats) {
  return {
    stateChunk: gameType === "daily" ? stats.dailyChallenge : stats.aiChallenge,
    stateKey: (gameType === "daily"
      ? "dailyChallenge"
      : "aiChallenge") as keyof Stats,
  };
}

AiMatchGame.displayName = "AiMatchGame";
