const idIdentifier = "{id}";
const colorIdentifier = "{color}";

export const join = "/game:join";
export const inviteOnly = "/game:inviteOnly";
export const joinWithId = (id: string) => `/game:join:${id}`;
export const joinWithIdWildcard = joinWithId(idIdentifier);

export const disconnected = () => "/disconnected";

export const gameStart = (id: string) => `/game:${id}:start`;
export const gameStartWildcard = gameStart(idIdentifier);

export const gameUpdate = (id: string) => `/game:${id}:update`;
export const gameUpdateWildcard = gameUpdate(idIdentifier);

export const selected = (id: string, color: string) =>
  `/game:${id}:selected:${color}`;
export const selectedWildcard = selected(idIdentifier, colorIdentifier);

export const rematchRequested = (id: string) => `/game:${id}:rematchRequested`;
export const rematchRequestedWildcard = rematchRequested(idIdentifier);

export const rematchStarted = (id: string) => `/game:${id}:rematchStarted`;
export const rematchStartedWildcard = rematchStarted(idIdentifier);
