const idPattern = ":id";

// top-level menus
export const home = "/";
export const multiplayer = "/multiplayer";
export const gameSingle = "/singleplayer/random";
export const learnToPlay = "/learnToPlay";
export const stats = "/stats";
export const about = "/about";

// multiplayer
export const game = "/multiplayer/random";
export const gameAi = "/multiplayer/ai";
export const gameChallenge = "/multiplayer/challenge";
export const gameInvite = "/multiplayer/invite";
export const gameWithId = (id: string) => `/multiplayer/invite/${id}`;
export const gameWithIdWildcard = gameWithId(idPattern);
