import * as React from "react";
import { Game as GameType } from "../../shared/types";
import { Field } from "./Field";
import { Interaction } from "./Interaction";
import { Progress } from "./Progress";
import { Message } from "./Message";

import "./Game.css";

type Props = {
  game: GameType;
  playerId: string;
  onColorSelected: (color: number) => void;
  moves?: number;
};

export const Game = React.memo<Props>(
  ({ game, onColorSelected, playerId, moves }) => {
    const gameRef = React.useRef<HTMLDivElement>(null);
    const [pointSize, setPointSize] = React.useState(20);

    const showMessage = game.type !== "single";

    React.useLayoutEffect(() => {
      if (gameRef.current) {
        const width = Math.min(
          window.innerWidth,
          gameRef.current.getBoundingClientRect().width
        );
        const actualWidth =
          window.innerHeight < 800
            ? Math.min(width, 400)
            : Math.min(width, 600);
        const pointSize = Math.floor(actualWidth / game.field.colors.length);
        setPointSize(pointSize);
      }
    }, []);

    const localPlayer = game.players.a === playerId ? "a" : "b";
    const gameWidth = pointSize * game.field.posessions.length;

    return (
      <div className="Game" ref={gameRef}>
        {showMessage && (
          <div className="Game__message" data-intro-id="status">
            <Message game={game} playerId={playerId} moves={moves} />
          </div>
        )}

        <Field
          field={game.field}
          pointSize={pointSize}
          localPlayer={localPlayer}
          onColorSelected={onColorSelected}
        />
        <div className="Game__Interaction" style={{ width: gameWidth }}>
          <Interaction onColorSelected={onColorSelected} width={gameWidth} />
          <Progress game={game} />
        </div>
      </div>
    );
  }
);

Game.displayName = "Game";
