import { Field, Posession, PlayerIndicator, Point, Game } from "./types";
import { create as createRng } from "random-seed";

export const COLORS_AMOUNT = 5;
export const STANDARD_FIELD_SIZE = 20;

export function generateField(
  width: number,
  height: number,
  seed: string
): Field {
  const generateRandomNumber = createRng(seed);
  const posessions: Posession[][] = [];
  const colors: number[][] = [];

  for (let y = 0; y < height; y++) {
    const currPosRow: Posession[] = [];
    const currColorRow: number[] = [];

    for (let x = 0; x < width; x++) {
      currPosRow.push("");
      currColorRow.push(generateRandomNumber(COLORS_AMOUNT));
    }

    posessions.push(currPosRow);
    colors.push(currColorRow);
  }

  return {
    posessions,
    colors,
  };
}

export function calculatePosession(
  { posessions }: Field,
  player: PlayerIndicator
): number {
  const height = posessions.length;
  const width = posessions[0].length;
  const total = width * height;
  let ownedByPlayer = 0;

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      if (posessions[y][x] === player) {
        ownedByPlayer++;
      }
    }
  }

  return ownedByPlayer / total;
}

export function getWinner(field: Field): PlayerIndicator | undefined {
  if (calculatePosession(field, "a") >= 0.5) {
    return "a";
  } else if (calculatePosession(field, "b") >= 0.5) {
    return "b";
  }
}

export function isSingleGameOver(field: Field, player: PlayerIndicator) {
  return calculatePosession(field, player) === 1;
}

export function playerOwnsNeighbour(
  { posessions }: Field,
  player: PlayerIndicator,
  x: number,
  y: number
): boolean {
  return (
    (posessions[y - 1] && posessions[y - 1][x] === player) || // above
    posessions[y][x + 1] === player || // right
    (posessions[y + 1] && posessions[y + 1][x] === player) || // underneath
    posessions[y][x - 1] === player // left
  );
}

export function playerOwnsPoint(
  { posessions }: Field,
  player: PlayerIndicator,
  x: number,
  y: number
): boolean {
  return posessions[y] && posessions[y][x] === player;
}

export function isEmptyPoint(
  { posessions }: Field,
  x: number,
  y: number
): boolean {
  return posessions[y] && posessions[y][x] === "";
}

export function pointIsColor(
  { colors }: Field,
  color: number,
  x: number,
  y: number
): boolean {
  return colors[y] && colors[y][x] === color;
}

type VisitedPointMap = Map<string, Point>;

export function allAvailablePointsOfColor(
  field: Field,
  color: number,
  player: PlayerIndicator
) {
  const length = field.colors.length;
  let points: VisitedPointMap = new Map();
  for (let y = 0; y < length; y++) {
    for (let x = 0; x < length; x++) {
      if (
        pointIsColor(field, color, x, y) &&
        isEmptyPoint(field, x, y) &&
        playerOwnsNeighbour(field, player, x, y) &&
        !points.has(coordKey(x, y))
      ) {
        addFreePointsFromColorAndPoint(field, color, x, y, points);
      }
    }
  }
  return Array.from(points.values());
}

export function pointInList({ x, y }: Point, points: Point[]) {
  return points.some((point) => point.x === x && point.y === y);
}

export function addFreePointsFromColorAndPoint(
  field: Field,
  color: number,
  x: number,
  y: number,
  visited: VisitedPointMap
) {
  floodFill(visited, field, color, x, y).values();
}

export function hasNeighbouringPoints(
  player: PlayerIndicator,
  field: Field,
  points: Point[]
) {
  return points.some((point) =>
    playerOwnsNeighbour(field, player, point.x, point.y)
  );
}

export function coordKey(x: number, y: number) {
  return `x${x}y${y}`;
}

function floodFill(
  points: VisitedPointMap,
  field: Field,
  color: number,
  x: number,
  y: number
): VisitedPointMap {
  points.set(coordKey(x, y), { x, y });

  // fill above
  if (
    isEmptyPoint(field, x, y - 1) &&
    pointIsColor(field, color, x, y - 1) &&
    !points.has(coordKey(x, y - 1))
  ) {
    floodFill(points, field, color, x, y - 1);
  }

  // fill to the right
  if (
    isEmptyPoint(field, x + 1, y) &&
    pointIsColor(field, color, x + 1, y) &&
    !points.has(coordKey(x + 1, y))
  ) {
    floodFill(points, field, color, x + 1, y);
  }

  // fill underneath
  if (
    isEmptyPoint(field, x, y + 1) &&
    pointIsColor(field, color, x, y + 1) &&
    !points.has(coordKey(x, y + 1))
  ) {
    floodFill(points, field, color, x, y + 1);
  }

  // fill to the left
  if (
    isEmptyPoint(field, x - 1, y) &&
    pointIsColor(field, color, x - 1, y) &&
    !points.has(coordKey(x - 1, y))
  ) {
    floodFill(points, field, color, x - 1, y);
  }

  return points;
}

export function copyField({ colors, posessions }: Field): Field {
  const newField: Field = {
    posessions: [],
    colors: [],
  };

  colors.forEach((colorRow, y) => {
    newField.colors.push(colorRow.slice());
    newField.posessions.push(posessions[y].slice());
  });

  return newField;
}

export function selectColor(
  field: Field,
  color: number,
  player: PlayerIndicator
): Field {
  // the field copy that we apply all operations on
  const newField = copyField(field);

  allAvailablePointsOfColor(newField, color, player).forEach(({ x, y }) => {
    newField.posessions[y][x] = player;
  });

  return newField;
}

export function calculatePosessionByPlayer({ posessions }: Field) {
  const height = posessions.length;
  const width = posessions[0].length;
  const fieldCount = width * height;
  const fieldCountByPlayer = {
    a: 0,
    b: 0,
  };

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const player = posessions[y][x];
      if (player) {
        fieldCountByPlayer[player]++;
      }
    }
  }

  return {
    a: fieldCountByPlayer.a / fieldCount,
    b: fieldCountByPlayer.b / fieldCount,
  };
}

export function playerHasMoveOptions(
  player: PlayerIndicator,
  field: Field
): boolean {
  const { posessions } = field;
  const height = posessions.length;
  const width = posessions[0].length;
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      // for each field owned by the player, check if there is an empty one
      // next to it so that they theoretically can take it in the next turn
      if (posessions[y][x] === player) {
        if (isEmptyPoint(field, x, y - 1)) {
          return true;
        }
        if (isEmptyPoint(field, x + 1, y)) {
          return true;
        }
        if (isEmptyPoint(field, x, y + 1)) {
          return true;
        }
        if (isEmptyPoint(field, x - 1, y)) {
          return true;
        }
      }
    }
  }
  return false;
}

export function aiSelectColor({
  field,
  player,
}: {
  field: Field;
  player: PlayerIndicator;
}): Field {
  // Generate an array with all available color
  // It's shuffled so that in case of several options, the AI is not as predictable
  const colors = shuffle(Array.from({ length: COLORS_AMOUNT }, (_, i) => i));

  let currentBest = {
    color: -1,
    percentage: -1,
    field: {} as Field,
  };
  // For every color, check the percentage after the move.
  // If the percentage of that move is higher, make it the newest best move.
  for (const color of colors) {
    const newField = selectColor(field, color, player);
    const percentage = calculatePosessionByPlayer(newField)[player];
    if (percentage > currentBest.percentage) {
      currentBest = {
        color,
        percentage,
        field: newField,
      };
    }
  }

  return currentBest.field;
}

export function aiGameMove({
  game,
  player,
  selectFn,
}: {
  game: Game;
  selectFn: (options: { field: Field; player: PlayerIndicator }) => Field;
  player: PlayerIndicator;
}): Game {
  game.field = selectFn({ field: game.field, player });
  const winner = getWinner(game.field);

  // If there is a winner, the game is over
  if (winner) {
    game.state = "over";
    game.winner = winner;
    return game;
  }

  const otherPlayer = player === "a" ? "b" : "a";

  // If one player ran out of move options, the other player won
  if (!playerHasMoveOptions(player, game.field)) {
    game.winner = game.players[otherPlayer];
    game.state = "over";
  } else if (!playerHasMoveOptions(otherPlayer, game.field)) {
    game.winner = game.players[player];
    game.state = "over";
  }

  return game;
}

export function singlePlayerMove({
  game,
  player,
  color,
}: {
  game: Game;
  player: PlayerIndicator;
  color: number;
}): Game {
  game.field = selectColor(game.field, color, player);

  // If the player has all fields, the game is over
  const isOver = calculatePosession(game.field, player) >= 1;
  if (isOver) {
    game.state = "over";
    return game;
  }

  return game;
}

export function createGame({
  playerId,
  seed,
  type = "random",
}: {
  playerId: string;
  seed?: string;
  type?: Game["type"];
}): Game {
  if (type === "daily-ai") {
    seed = getSeedFromDate();
  } else if (!seed) {
    // If no seed was provided, get a random one
    seed = Math.random().toString();
  }
  return {
    id: Math.random().toString(),
    players: { a: playerId, b: undefined },
    seed,
    field: generateField(STANDARD_FIELD_SIZE, STANDARD_FIELD_SIZE, seed),
    currentPlayer: playerId,
    state: "waiting",
    createdAt: new Date(),
    rematchRequested: {
      a: false,
      b: false,
    },
    type,
  };
}

/**
 * Generates a seed from the current date in the format `DD-MM-YYYY`.
 */
function getSeedFromDate(): string {
  const date = new Date();
  return `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
}

function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
