import * as React from "react";

import * as Urls from "../../shared/urls";
import { Menu } from "../menu/Menu";

const menuConfig = {
  headline: "Multiplayer",
  items: [
    { url: Urls.gameAi, title: "Challenge the AI" },
    { url: Urls.game, title: "Join a random game" },
    { url: Urls.gameInvite, title: "Create a private game" },
  ],
};

export const Multi = React.memo(() => {
  return (
    <Menu headline={menuConfig.headline} items={menuConfig.items} backTo="/" />
  );
});

Multi.displayName = "Multi";
