import * as React from "react";
import { calculatePosessionByPlayer } from "../../shared/logic";
import { Game } from "../../shared/types";

import "./Progress.css";
import "./colors.css";

type Props = {
  game: Game;
};

export const Progress = React.memo<Props>(({ game }) => {
  const { field } = game;
  const hasTwoProgressBars = game.type !== "single";

  const [progressByPlayer, setProgressByPlayer] = React.useState(() =>
    fieldToProgressBars(game)
  );
  React.useEffect(
    () => setProgressByPlayer(fieldToProgressBars(game)),
    [field]
  );
  return (
    <div className={`Progress type-${game.type}`} data-intro-id="progress">
      <div className="ProgressBar ProgressBar__a">
        <div className="ProgressBar__bar color__a" style={progressByPlayer.a} />
        <span className="ProgressBar__label">{`${Math.floor(
          progressByPlayer.a.percent
        )}%`}</span>
      </div>
      {hasTwoProgressBars && (
        <div className="ProgressBar ProgressBar__b">
          <div
            className="ProgressBar__bar color__b"
            style={progressByPlayer.b}
          />
          <span className="ProgressBar__label">{`${Math.floor(
            progressByPlayer.b.percent
          )}%`}</span>
        </div>
      )}
    </div>
  );
});

Progress.displayName = "Progress";

function fieldToProgressBars({ field, type }: Game) {
  const posession = calculatePosessionByPlayer(field);
  const percentA = posession.a * 100;
  const percentB = posession.b * 100;
  const widthMultiplicator = type === "single" ? 1 : 2;
  return {
    a: {
      percent: percentA,
      width: `${percentA < 1 ? 0 : widthMultiplicator * percentA}%`,
    },
    b: {
      percent: percentB,
      width: `${percentB < 1 ? 0 : widthMultiplicator * percentB}%`,
    },
  };
}
