import { assign, createMachine, forwardTo } from "xstate";
import * as Nes from "@hapi/nes/lib/client";
import { Game } from "../shared/types";
import * as Messages from "../shared/messages";

type MultiplayerEvent =
  | { type: "CONNECTING"; client: Nes.Client }
  | { type: "CONNECTED" }
  | { type: "GAME_INITIALIZED"; game: Game }
  | { type: "GAME_STARTED"; game: Game }
  | { type: "COLOR_SELECTED"; color: number }
  | { type: "REMOTE_UPDATE"; game: Game }
  | { type: "REMATCH_REQUESTED"; gameId: string }
  | { type: "REMATCH_STARTED"; game: Game }
  | { type: "DISCONNECT" }
  | { type: "LEAVE" };

type Context = {
  client?: Nes.Client;
  game?: Game;
  initialGameId?: string;
  initialInviteOnly?: boolean;
  onAttempt: () => void;
  onWin: () => void;
  onLoss: () => void;
};

export const MultiPlayerMachine = createMachine<Context, MultiplayerEvent>(
  {
    initial: "connecting",
    on: {
      DISCONNECT: { target: "disconnected", actions: ["disconnect"] },
    },
    invoke: {
      id: "disconnectListener",
      src: "disconnectListener",
    },
    states: {
      connecting: {
        on: {
          CONNECTING: {
            actions: [forwardTo("disconnectListener"), "assignClient"],
          },
          CONNECTED: {
            target: "waiting",
          },
        },
        invoke: {
          src: "connect",
        },
      },
      waiting: {
        on: {
          GAME_INITIALIZED: {
            actions: "assignGame",
          },
          GAME_STARTED: {
            target: "playing",
            actions: ["onAttempt", "assignGame"],
          },
        },
        invoke: {
          src: "join",
        },
      },
      playing: {
        always: [
          {
            cond: "didPlayerWin",
            target: "over",
            actions: ["onWin", "assignGame"],
          },
          {
            cond: "didOpponentWin",
            target: "over",
            actions: ["onLoss", "assignGame"],
          },
        ],
        invoke: {
          src: "listenToGameUpdates",
        },
        on: {
          COLOR_SELECTED: {
            actions: "selectColor",
          },
          REMOTE_UPDATE: {
            actions: "assignGame",
          },
          LEAVE: { target: "disconnected", actions: ["onLoss", "disconnect"] },
        },
      },
      over: {
        invoke: { src: "listenToRematch" },
        on: {
          REMATCH_REQUESTED: {
            actions: "requestRematch",
          },
          REMOTE_UPDATE: {
            actions: "assignGame",
          },
          REMATCH_STARTED: {
            target: "playing",
            actions: ["onAttempt", "assignGame"],
          },
        },
      },
      disconnected: {
        type: "final",
        entry: "unsubscribeFromGame",
      },
    },
  },
  {
    actions: {
      assignClient: assign({
        client: (ctx, event) =>
          event.type === "CONNECTING" ? event.client : ctx.client,
      }),
      assignGame: assign({
        game: (ctx, event) => {
          return event.type === "GAME_INITIALIZED" ||
            event.type === "GAME_STARTED" ||
            event.type === "REMOTE_UPDATE" ||
            event.type === "REMATCH_STARTED"
            ? event.game
            : ctx.game;
        },
      }),
      disconnect: (ctx) => {
        ctx.client?.disconnect();
      },
      selectColor: (ctx, event) => {
        const { client, game } = ctx;
        if (!game || !client) {
          return;
        } else if (event.type === "COLOR_SELECTED") {
          client.request(Messages.selected(game.id, event.color.toString()));
        }
      },
      requestRematch: (ctx, event) => {
        const { client, game } = ctx;
        if (!game || !client) {
          return;
        } else if (event.type === "REMATCH_REQUESTED") {
          client.request(Messages.rematchRequested(event.gameId));
        }
      },
      unsubscribeFromGame: (ctx) => {
        if (!ctx.client || !ctx.game) {
          return;
        }
        ctx.client.unsubscribe(Messages.gameUpdate(ctx.game.id));
      },
      onAttempt: (ctx) => ctx.onAttempt(),
      onWin: (ctx) => ctx.onWin(),
      onLoss: (ctx) => ctx.onLoss(),
    },
    services: {
      connect: () => (callback) => {
        const client = new Nes.Client(process.env.SERVER_URL ?? "");
        console.log("connecting...", process.env.SERVER_URL);
        callback({ type: "CONNECTING", client });

        client.connect().then(() => {
          console.log("connected...");
          callback({ type: "CONNECTED" });
        });
      },
      join: (ctx) => (callback) => {
        if (!ctx.client) {
          return;
        }
        let game: Game | undefined;
        const client = ctx.client;
        let message = Messages.join;
        if (ctx.initialGameId) {
          message = Messages.joinWithId(ctx.initialGameId);
        } else if (ctx.initialInviteOnly) {
          message = Messages.inviteOnly;
        }
        client.request(message).then(({ payload }) => {
          game = payload as Game;

          // We're the second player, so we can start
          if (game.state === "playing") {
            callback({ type: "GAME_STARTED", game });
          } else {
            // We started a new game and are waiting for another player
            callback({ type: "GAME_INITIALIZED", game });
            client.subscribe(
              Messages.gameStart(game.id),
              (updatedGame: Game) => {
                if (updatedGame.state === "playing") {
                  client
                    .unsubscribe(Messages.gameStart(updatedGame.id))
                    .then(() => {
                      callback({
                        type: "GAME_STARTED",
                        game: updatedGame,
                      });
                    });
                }
              }
            );
          }
        });
      },
      listenToGameUpdates: (ctx) => (callback) => {
        if (!ctx.game || !ctx.client) {
          return;
        }

        ctx.client.subscribe(
          Messages.gameUpdate(ctx.game.id),
          (updatedGame: Game) => {
            callback({ type: "REMOTE_UPDATE", game: updatedGame });
          }
        );

        return () => {
          if (ctx.client && ctx.game) {
            return ctx.client.unsubscribe(Messages.gameUpdate(ctx.game.id));
          }
        };
      },
      listenToRematch: (ctx) => (callback) => {
        if (!ctx.game || !ctx.client) {
          return;
        }

        const gameId = ctx.game.id;

        ctx.client.subscribe(
          Messages.gameUpdate(gameId),
          (updatedGame: Game) => {
            callback({ type: "REMOTE_UPDATE", game: updatedGame });
          }
        );

        // The rematch game has been started
        ctx.client.subscribe(
          Messages.rematchStarted(gameId),
          (startedGame: Game) => {
            callback({ type: "REMATCH_STARTED", game: startedGame });
          }
        );

        return () => {
          if (ctx.client && ctx.game) {
            return Promise.all([
              ctx.client.unsubscribe(Messages.gameUpdate(gameId)),
              ctx.client.unsubscribe(Messages.rematchStarted(gameId)),
            ]);
          }
        };
      },
      disconnectListener: () => (callback, onReceive) => {
        function listenToDisconnect(client: Nes.Client) {
          client.onError = () => {
            callback({ type: "DISCONNECT" });
          };
          client.onDisconnect = () => {
            callback({ type: "DISCONNECT" });
          };
        }

        onReceive((event: MultiplayerEvent) => {
          if (event.type === "CONNECTING") {
            // Handle disconnects -> show a message
            listenToDisconnect(event.client);
            // Also make sure to disconnect the client
            // Attention: this means that users cannot reload the page
            // without disconnecting. Therefore they will lose the game.
            // There is currently no way to authenticate users and have stable
            // player ids between sessions. This simplifies the server logic a lot.
            // And it means users don't have to sign up.
            window.addEventListener("beforeunload", function (e) {
              event.client.disconnect();
              delete e["returnValue"];
            });
          }
        });
      },
    },

    guards: {
      didPlayerWin: (ctx) =>
        ctx.game?.state === "over" && ctx.game?.winner === ctx.client?.id,
      didOpponentWin: (ctx) =>
        ctx.game?.state === "over" && ctx?.game?.winner !== ctx.client?.id,
      // isOver: (ctx, event) => {
      //   let game = event.type === "REMOTE_UPDATE" ? event.game : ctx.game;
      //   return game?.state === "over";
      // },
    },
  }
);
