import * as React from "react";
import { Game as GameType } from "../../shared/types";
import { Game } from "./Game";

import "./Rematch.css";

type Props = {
  game: GameType;
  playerId: string;
  onRematchRequested: (gameId: string) => void;
  moves?: number;
};

export const Rematch = React.memo<Props>(
  ({ game, playerId, onRematchRequested, moves }) => {
    const localPlayer = game.players.a === playerId ? "a" : "b";
    const opponent = game.players.a === playerId ? "b" : "a";
    const hasPlayerRematchRequested = game.rematchRequested[localPlayer];
    const hasOpponentRematchRequested = game.rematchRequested[opponent];

    const onRematchClick = React.useCallback(() => {
      onRematchRequested(game.id);
    }, [game.id]);

    let message = "";
    let ctaMessage = "";
    if (hasOpponentRematchRequested && !hasPlayerRematchRequested) {
      message = "Your opponent requested a rematch";
      ctaMessage = "Accept rematch";
    } else if (!hasOpponentRematchRequested && hasPlayerRematchRequested) {
      message = "Waiting for opponent to accept rematch";
    } else if (!hasPlayerRematchRequested && !hasOpponentRematchRequested) {
      ctaMessage = "Request a rematch";
    } else {
      message = "Waiting for rematch to start";
    }

    const rematchCta = hasPlayerRematchRequested ? null : (
      <button onClick={onRematchClick} className="Rematch__cta">
        {ctaMessage}
      </button>
    );

    return (
      <div className="Rematch">
        <div className="Rematch__message">
          {message}
          {rematchCta}
        </div>

        <Game
          game={game}
          playerId={playerId}
          onColorSelected={() => {}}
          moves={moves}
        />
      </div>
    );
  }
);
