import { atomWithStorage } from "jotai/utils";

export type Stats = {
  single: {
    started: number;
    finished: number;
    sumMoves: number;
  };
  dailyChallenge: {
    attempts: number;
    wins: number;
    losses: number;
    streak: number;
  };
  aiChallenge: {
    attempts: number;
    wins: number;
    losses: number;
    streak: number;
  };
  multiplayer: {
    matches: number;
    wins: number;
    losses: number;
    streak: number;
  };
  version: number;
};

export const statsAtom = atomWithStorage<Stats>("stats", {
  single: {
    started: 0,
    finished: 0,
    sumMoves: 0,
  },
  dailyChallenge: {
    attempts: 0,
    wins: 0,
    losses: 0,
    streak: 0,
  },
  aiChallenge: {
    attempts: 0,
    wins: 0,
    losses: 0,
    streak: 0,
  },
  multiplayer: {
    matches: 0,
    wins: 0,
    losses: 0,
    streak: 0,
  },
  version: 1,
});
