import * as React from "react";
import { RouteContent } from "../components/RouteContent";
import "./About.css";

export const About = () => {
  return (
    <RouteContent backTo="/">
      <div className="About">
        <h2>About</h2>
        <p>
          Colorbattle was developed by{" "}
          <a href="https://janmonschke.com" target="_blank">
            Jan Monschke
          </a>
          .
        </p>
        <p>
          It is a recreation of a{" "}
          <a href="https://en.wikipedia.org/wiki/Browser_game" target="_blank">
            Flash game
          </a>{" "}
          that he used to play a lot back in the days when Flash games were
          still a thing 😅.
        </p>
        <p>
          That game at some point vanished, so he decided the build it again.
          But no Flash was used in the development of this game 😉.
        </p>
      </div>
    </RouteContent>
  );
};
