import * as React from "react";
import { RouteContent } from "../components/RouteContent";
import { Statistics } from "../components/Statistics";

export const Stats = () => {
  return (
    <RouteContent backTo="/">
      <Statistics />
    </RouteContent>
  );
};
