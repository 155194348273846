import { createMachine, assign } from "xstate";
import { createGame, singlePlayerMove } from "../shared/logic";
import { Game, PlayerIndicator } from "../shared/types";

type SinglePlayerEvent =
  | { type: "COLOR_SELECTED"; color: number }
  | { type: "PLAY_AGAIN" };

type Context = {
  game: Game;
  moves: number;
  onStarted: () => void;
  onFinished: (moves: number) => void;
};

const localPlayer: PlayerIndicator = "a";

export const SinglePlayerMachine = createMachine<Context, SinglePlayerEvent>(
  {
    initial: "generating",
    states: {
      generating: {
        entry: "onStarted",
        after: {
          100: {
            target: "playing",
            actions: "generateGame",
          },
        },
      },
      playing: {
        always: {
          target: "over",
          cond: "isOver",
          actions: "onFinished",
        },
        on: {
          COLOR_SELECTED: {
            target: "playing",
            actions: "setColor",
          },
        },
      },
      over: {
        on: {
          PLAY_AGAIN: {
            target: "generating",
          },
        },
      },
    },
  },
  {
    actions: {
      generateGame: assign({
        game: (_) => {
          return generateGame({
            initialPlayer: localPlayer,
          });
        },
        moves: (_) => 0,
      }),
      setColor: assign({
        game: (ctx, event) => {
          return event.type === "COLOR_SELECTED"
            ? {
                ...singlePlayerMove({
                  game: ctx.game,
                  player: localPlayer,
                  color: event.color,
                }),
              }
            : ctx.game;
        },
        moves: (ctx, event) => {
          return event.type === "COLOR_SELECTED" ? ctx.moves + 1 : ctx.moves;
        },
      }),
      onStarted: (ctx) => ctx.onStarted(),
      onFinished: (ctx) => ctx.onFinished(ctx.moves),
    },
    guards: {
      isOver: (ctx) => ctx.game.state === "over",
    },
  }
);

function generateGame({
  initialPlayer,
}: {
  initialPlayer: PlayerIndicator;
}): Game {
  const game = createGame({ playerId: initialPlayer, type: "single" });
  game.field.posessions[0][0] = localPlayer;
  game.state = "playing";
  game.players = {
    a: localPlayer,
    b: undefined,
  };
  return game;
}
