import * as React from "react";

import * as Urls from "../../shared/urls";
import { Menu } from "../menu/Menu";

const menuConfig = {
  headline: "Colorbattle",
  items: [
    { url: Urls.gameChallenge, title: "Daily Challenge" },
    { url: Urls.multiplayer, title: "Multiplayer" },
    { url: Urls.gameSingle, title: "Singleplayer" },
    { url: Urls.learnToPlay, title: "How to play" },
    { url: Urls.stats, title: "Statistics" },
    { url: Urls.about, title: "About" },
  ],
};

export const Home = React.memo(() => {
  return <Menu headline={menuConfig.headline} items={menuConfig.items} />;
});

Home.displayName = "Home";
