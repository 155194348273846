import * as React from "react";
import { Link } from "wouter";
import { Tile } from "./Tile";

import "./Menu.css";
import { RouteContent } from "../components/RouteContent";

type Props = {
  headline: string;
  items: {
    url: string;
    title: string;
  }[];
  backTo?: string;
};

export const Menu = React.memo<Props>(({ headline, items, backTo }) => {
  return (
    <RouteContent backTo={backTo}>
      <div className="Menu1">
        <h1>{headline}</h1>
        {items.map(({ url, title }) => (
          <Link key={url} to={url} className="btn m-2">
            {title}
          </Link>
        ))}
      </div>
      <div className="Menu__backdrop">
        <Tile />
        <Tile />
        <Tile />
        <Tile />
        <Tile />
        <Tile />
        <Tile />
        <Tile />
      </div>
    </RouteContent>
  );
});

Menu.displayName = "Menu";
