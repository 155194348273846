import * as React from "react";
import cx from "classnames";
import type { Posession } from "../../shared/types";

import "./colors.css";
import "./Point.css";

type Props = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  color: number;
  posession: Posession;
  highlightedPosession?: Posession;
  onHover?: (x: number, y: number, color: number) => void;
  onClick?: (color: number) => void;
};

export const Point = React.memo<Props>(
  ({
    color,
    x,
    y,
    width = 20,
    height = 20,
    onHover,
    highlightedPosession,
    posession,
    onClick,
  }) => {
    const onMouseOver = React.useCallback(
      () =>
        onHover && x !== undefined && y !== undefined && onHover(x, y, color),
      [onHover, x, y]
    );
    const onClickHandler = React.useCallback(
      () => onClick && onClick(color),
      [color, onClick]
    );
    const style = React.useMemo(() => ({ width, height }), [width, height]);
    return (
      <div
        className={cx(
          "Point",
          highlightedPosession && "is-highlighted",
          `color__${posession || highlightedPosession || color}`
        )}
        style={style}
        onMouseOver={onMouseOver}
        onClick={highlightedPosession ? onClickHandler : undefined}
      ></div>
    );
  }
);

Point.displayName = "Point";
