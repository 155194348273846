import * as React from "react";
import { Link } from "wouter";
import "./RouteContent.css";

export const RouteContent = ({
  hasWrapper = true,
  backTo,
  onBack = () => {},
  backLinkText,
  children,
}: {
  hasWrapper?: boolean;
  backTo?: string;
  onBack?: () => void;
  backLinkText?: string;
  children: React.ReactNode;
}) => {
  const navClasses = hasWrapper
    ? "navbar col-7 RouteContent__nav RouteContent__wrapped"
    : "navbar RouteContent__nav";
  const contentClasses = hasWrapper
    ? "panel col-7 RouteContent__content RouteContent__wrapped"
    : "RouteContent__content";
  return (
    <div className="RouteContent">
      {backTo && (
        <header className={navClasses}>
          <section className="navbar-section">
            <Link to={backTo} className="btn my-2" onClick={onBack}>
              {`🔙 ${backLinkText || ""}`}
            </Link>
          </section>
        </header>
      )}

      <div className={contentClasses}>{children}</div>
    </div>
  );
};
