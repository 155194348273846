import * as React from "react";
import {
  allAvailablePointsOfColor,
  coordKey,
  isEmptyPoint,
  playerOwnsPoint,
  pointInList,
} from "../../shared/logic";
import type { Field as FieldType, PlayerIndicator } from "../../shared/types";
import { Point } from "./Point";

import "./Field.css";

const Row: React.FC = ({ children }) => <div className="Row">{children}</div>;

Row.displayName = "Row";

type HighlightedPoints = Record<string, boolean>;
const emptyHighlights: HighlightedPoints = {};

type Props = {
  field: FieldType;
  pointSize: number;
  localPlayer: PlayerIndicator;
  onColorSelected: (color: number, player: PlayerIndicator) => void;
};

export const Field = React.memo<Props>(
  ({ field, pointSize, localPlayer, onColorSelected }) => {
    const [columnAndRowArray] = React.useState(() => {
      const length = field.colors.length;
      return new Array(length).fill(1);
    });

    const [highlightedPoints, setHighlightedPoints] =
      React.useState<HighlightedPoints>(emptyHighlights);

    const onHover = React.useCallback(
      (x: number, y: number, color: number) => {
        if (
          !playerOwnsPoint(field, localPlayer, x, y) &&
          isEmptyPoint(field, x, y)
        ) {
          const availablePoints = allAvailablePointsOfColor(
            field,
            color,
            localPlayer
          );
          const hoveredPointIsAvailable = pointInList(
            { x, y },
            availablePoints
          );

          if (hoveredPointIsAvailable) {
            setHighlightedPoints(
              availablePoints.reduce<HighlightedPoints>((highlights, point) => {
                highlights[coordKey(point.x, point.y)] = true;
                return highlights;
              }, {})
            );
          } else {
            setHighlightedPoints(emptyHighlights);
          }
        } else {
          setHighlightedPoints(emptyHighlights);
        }
      },
      [field]
    );

    const onClick = React.useCallback(
      (color: number) => {
        onColorSelected(color, localPlayer);
        setHighlightedPoints(emptyHighlights);
      },
      [onColorSelected, localPlayer]
    );

    return (
      <div className="Field" data-intro-id="field">
        {columnAndRowArray.map((_, rowIdx) => {
          return (
            <Row key={`row${rowIdx}`}>
              {columnAndRowArray.map((_, pointIdx) => (
                <Point
                  key={`point${pointIdx}`}
                  color={field.colors[rowIdx][pointIdx]}
                  posession={field.posessions[rowIdx][pointIdx]}
                  x={pointIdx}
                  y={rowIdx}
                  width={pointSize}
                  height={pointSize}
                  onHover={onHover}
                  onClick={onClick}
                  highlightedPosession={
                    highlightedPoints[coordKey(pointIdx, rowIdx)]
                      ? localPlayer
                      : ""
                  }
                />
              ))}
            </Row>
          );
        })}
      </div>
    );
  }
);

Field.displayName = "Field";
