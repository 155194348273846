import { useMachine } from "@xstate/react";
import * as React from "react";
import { useUpdateAtom } from "jotai/utils";
import { SinglePlayerMachine } from "../machines/single-player-machine";
import { Game } from "./components/Game";
import { PlayAgain } from "./components/PlayAgain";
import { statsAtom } from "./persistence";

import "intro.js/minified/introjs.min.css";
import { RouteContent } from "./components/RouteContent";

export const SinglePlayerGame: React.FC = () => {
  const setStats = useUpdateAtom(statsAtom);
  const [state, send] = useMachine(SinglePlayerMachine, {
    devTools: true,
    context: {
      onStarted: () =>
        setStats((oldStats) => ({
          ...oldStats,
          single: {
            ...oldStats.single,
            started: oldStats.single.started + 1,
          },
        })),
      onFinished: (moves) =>
        setStats((oldStats) => ({
          ...oldStats,
          single: {
            ...oldStats.single,
            finished: oldStats.single.finished + 1,
            sumMoves: oldStats.single.sumMoves + moves,
          },
        })),
    },
  });

  const onColorSelected = React.useCallback((color: number) => {
    send({ type: "COLOR_SELECTED", color });
  }, []);

  const { game, moves } = state.context;

  switch (state.value) {
    case "playing":
      return (
        <RouteContent backTo="/" hasWrapper={false}>
          <Game
            game={state.context.game}
            playerId="a"
            onColorSelected={onColorSelected}
          />
        </RouteContent>
      );
    case "over":
      return (
        <RouteContent backTo="/" hasWrapper={false}>
          <PlayAgain
            game={game}
            playerId="a"
            moves={moves}
            onPlayAgain={() => {
              send({ type: "PLAY_AGAIN" });
            }}
          />
        </RouteContent>
      );
  }

  return null;
};

SinglePlayerGame.displayName = "SinglePlayerGame";
