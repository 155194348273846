import * as React from "react";
import "./Tile.css";
import "../components/colors.css";

const width = window.innerWidth;
const height = window.innerHeight;

export const Tile = React.memo(() => {
  const [animationValues, setAnimationValues] = React.useState(() =>
    generateAnimation()
  );
  const [currentPosition, setCurrentPosition] = React.useState({
    top: animationValues.startY,
    left: animationValues.startX,
    rotation: animationValues.startRotation,
  });

  React.useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentPosition({
        top: animationValues.endY,
        left: animationValues.endX,
        rotation: animationValues.endRotation,
      });
    }, 100);
    return () => clearTimeout(timeout);
  }, [animationValues]);

  const updateAnimation = React.useCallback(() => {
    setAnimationValues(generateAnimation());
  }, []);

  return (
    <div
      className={`Tile color__${animationValues.color}`}
      style={{
        top: currentPosition.top,
        left: currentPosition.left,
        transform: `rotate(${currentPosition.rotation}deg)`,
        transitionDelay: `${animationValues.delay}s`,
      }}
      onTransitionEnd={updateAnimation}
    />
  );
});

function generateAnimation() {
  const direction = Math.random() >= 0.5 ? -1 : 1;
  const color = Math.floor(Math.random() * 5);
  return {
    startX: direction * width,
    endX: -1 * direction * width * 1.3,
    startY: Math.random() * height,
    endY: Math.random() * height,
    startRotation: 0,
    endRotation: 540 + Math.random() * 720,
    color,
    delay: Math.random() * 3,
  };
}
