import * as React from "react";
import { useAtom } from "jotai";
import { statsAtom } from "../persistence";

import "./Statistics.css";

export const Statistics = () => {
  const [stats] = useAtom(statsAtom);

  const hasStartedSingleMatches = stats.single.started > 0;
  const hasFinishedSingleMatches = stats.single.finished > 0;
  const hasPlayedMultiMatches = stats.multiplayer.matches > 0;
  const isOnMultiStreak = stats.multiplayer.streak > 0;
  const hasPlayedDailyChallenge = stats.dailyChallenge.attempts > 0;
  const isOnDailyStreak = stats.dailyChallenge.streak > 0;
  const hasPlayedAiMatches = stats.aiChallenge.attempts > 0;
  const isOnAiStreak = stats.aiChallenge.streak > 0;

  return (
    <div className="Statistics">
      <h2>Statistics</h2>
      <h3>Single</h3>
      <p>
        {hasStartedSingleMatches ? (
          <>
            You have started <strong>{stats.single.started} matches</strong> and{" "}
            <strong>finished {stats.single.finished}</strong> of those.
            {hasFinishedSingleMatches ? (
              <>
                On average you needed{" "}
                <strong>
                  {Math.round(stats.single.sumMoves / stats.single.finished)}{" "}
                  moves
                </strong>{" "}
                to finish a game and you made{" "}
                <strong>{stats.single.sumMoves} moves in total</strong>.
              </>
            ) : null}
          </>
        ) : (
          <>You have not yet played single player matches.</>
        )}
      </p>

      <h3>Multiplayer</h3>
      <p>
        {hasPlayedMultiMatches ? (
          <>
            You have played{" "}
            <strong>
              {stats.multiplayer.matches}{" "}
              {simplePlural(stats.multiplayer.matches, "match", "matches")}
            </strong>
            , <strong>won {stats.multiplayer.wins || "none"}</strong> of those{" "}
            <strong>and lost {stats.multiplayer.losses} </strong>
            {simplePlural(stats.multiplayer.losses, "match", "matches")}{" "}
            {isOnMultiStreak ? (
              <>
                You are currently on a streak of {stats.multiplayer.streak}{" "}
                {simplePlural(stats.multiplayer.streak, "match", "matches")}.
              </>
            ) : (
              <>You lost the last match.</>
            )}
          </>
        ) : (
          <>You have not yet played multiplayer matches.</>
        )}
      </p>

      <h3>Daily challenge</h3>
      <p>
        {hasPlayedDailyChallenge ? (
          <>
            You have played{" "}
            <strong>
              {stats.dailyChallenge.attempts}{" "}
              {simplePlural(stats.dailyChallenge.attempts, "match", "matches")}
            </strong>
            , <strong>won {stats.dailyChallenge.wins || "none"}</strong> of
            those <strong>and lost {stats.dailyChallenge.losses} </strong>
            {simplePlural(
              stats.dailyChallenge.losses,
              "match",
              "matches"
            )}.{" "}
            {isOnDailyStreak ? (
              <>
                You are currently on a streak of {stats.dailyChallenge.streak}{" "}
                {simplePlural(stats.dailyChallenge.streak, "match", "matches")}.
              </>
            ) : (
              <>You lost the last match.</>
            )}
          </>
        ) : (
          <>You have not yet played daily challenges.</>
        )}
      </p>

      <h3>AI challenges</h3>
      <p>
        {hasPlayedAiMatches ? (
          <>
            You have played{" "}
            <strong>
              {stats.aiChallenge.attempts}{" "}
              {simplePlural(stats.aiChallenge.attempts, "match", "matches")}
            </strong>
            , <strong>won {stats.aiChallenge.wins || "none"}</strong> of those{" "}
            <strong>and lost {stats.dailyChallenge.losses} </strong>
            {simplePlural(
              stats.dailyChallenge.losses,
              "match",
              "matches"
            )}.{" "}
            {isOnAiStreak ? (
              <>
                You are currently on a streak of {stats.aiChallenge.streak}{" "}
                {simplePlural(stats.aiChallenge.streak, "match", "matches")}.
              </>
            ) : (
              <>You lost the last match.</>
            )}
          </>
        ) : (
          <>You have not yet played against the multiplayer AI.</>
        )}
      </p>
    </div>
  );
};

/**
 * Chooses the correct case for the english language
 */
function simplePlural(count: number, singular: string, plural: string) {
  return count === 1 ? singular : plural;
}
