import { useMachine } from "@xstate/react";
import * as React from "react";
import { useUpdateAtom } from "jotai/utils";
import { MultiPlayerMachine } from "../machines/multi-player-machine";
import { Game } from "./components/Game";
import { Rematch } from "./components/Rematch";
import * as Urls from "../shared/urls";
import { Game as TGame } from "../shared/types";
import { RouteContent } from "./components/RouteContent";
import { Loader } from "./components/Loader";
import { statsAtom } from "./persistence";

import "./MultiplayerGame.css";

export const MultiplayerGame: React.FC<{
  gameId?: string;
  type?: TGame["type"];
}> = ({ gameId, type = "random" }) => {
  const setStats = useUpdateAtom(statsAtom);
  const [state, send] = useMachine(MultiPlayerMachine, {
    devTools: true,
    context: {
      initialGameId: gameId,
      initialInviteOnly: type === "invite",
      onAttempt: () => {
        setStats((oldStats) => ({
          ...oldStats,
          multiplayer: {
            ...oldStats.multiplayer,
            matches: oldStats.multiplayer.matches + 1,
          },
        }));
      },
      onLoss: () => {
        setStats((oldStats) => ({
          ...oldStats,
          multiplayer: {
            ...oldStats.multiplayer,
            losses: oldStats.multiplayer.losses + 1,
            streak: 0,
          },
        }));
      },
      onWin: () => {
        setStats((oldStats) => ({
          ...oldStats,
          multiplayer: {
            ...oldStats.multiplayer,
            wins: oldStats.multiplayer.wins + 1,
            streak: oldStats.multiplayer.streak + 1,
          },
        }));
      },
    },
  });

  const onColorSelected = React.useCallback((color: number) => {
    send({ type: "COLOR_SELECTED", color });
  }, []);

  const onLeave = React.useCallback(() => {
    send("LEAVE");
    send("DISCONNECT");
  }, [send]);

  const { client, game } = state.context;

  switch (state.value) {
    case "connecting":
      return (
        <div className="Multi__Loading">
          Connecting
          <Loader />
        </div>
      );
    case "waiting":
      const gameUrl = `${location.host}${Urls.gameWithId(game?.id || "")}`;
      const isInviteOnly = game?.type === "invite";
      return (
        <RouteContent
          backTo={Urls.multiplayer}
          onBack={onLeave}
          hasWrapper={true}
        >
          <div className="Multi__Loading">
            Waiting for opponent to join
            <Loader />
          </div>
          {isInviteOnly && (
            <>
              <p className="Multi__shareMsg">
                Share the following URL with a friend to invite them to play
              </p>
              <input
                type="text"
                value={gameUrl}
                className="Multi__URL"
                readOnly
              />
            </>
          )}
        </RouteContent>
      );
    case "playing":
      if (game && client) {
        return (
          <RouteContent
            backTo={Urls.multiplayer}
            onBack={onLeave}
            hasWrapper={false}
            backLinkText="Leave match"
          >
            <Game
              game={game}
              playerId={client.id}
              onColorSelected={onColorSelected}
            />
          </RouteContent>
        );
      }
    case "over":
      if (game && client) {
        return (
          <RouteContent
            backTo={Urls.multiplayer}
            onBack={onLeave}
            hasWrapper={false}
          >
            <Rematch
              game={game}
              playerId={client.id}
              onRematchRequested={(gameId) => {
                send({ type: "REMATCH_REQUESTED", gameId });
              }}
            />
          </RouteContent>
        );
      }

    case "disconnected":
      return (
        <RouteContent
          backTo={Urls.multiplayer}
          onBack={onLeave}
          hasWrapper={false}
        >
          <p>You were disconnected</p>
        </RouteContent>
      );
    default:
      return <p />;
  }
};

MultiplayerGame.displayName = "MultiplayerGame";
